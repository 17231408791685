
import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { calendarNumberOutline, calendarOutline, calendarSharp, pieChart, pieChartOutline, pieChartSharp, settings, settingsOutline, settingsSharp, help, helpOutline, information, informationOutline } from 'ionicons/icons';
import SettingsService from "@/servies/settings.service";


// Use matchMedia to check the user preference
const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

// Add or remove the "dark" class based on if the media query matches
function toggleDarkTheme(shouldAdd: boolean) {
  document.body.classList.toggle('dark', shouldAdd);
}

toggleDarkTheme(prefersDark.matches);

// Listen for changes to the prefers-color-scheme media query
prefersDark.addEventListener("change",(mediaQuery) => toggleDarkTheme(mediaQuery.matches));



export default defineComponent({
  name: 'App',
  components: {
    IonApp, 
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
  },
  setup() {
    const appPages = [
      {
        title: "pages.times",
        url: '/',
        iosIcon: calendarNumberOutline,
        mdIcon: calendarOutline,
      },
      {
        title: 'pages.evaluation',
        url: '/evaluation',
        iosIcon: pieChartOutline,
        mdIcon: pieChart
      },
      {
        title: 'pages.settings',
        url: '/settings',
        iosIcon: settingsOutline,
        mdIcon: settings
      },
      {
        title: 'pages.help',
        url: '/help',
        iosIcon: helpOutline,
        mdIcon: help
      },
      {
        title: 'pages.about',
        url: '/about',
        iosIcon: informationOutline,
        mdIcon: information
      }
    ];
    
    const route = useRoute();
    
    return { 
      appPages, 
      calendarNumberOutline, 
      calendarSharp, 
      pieChartOutline, 
      pieChartSharp, 
      settingsOutline, 
      settingsSharp,
      selectedIndex(): number {
        for(let i = 0; i < appPages.length; i++)
        {
          if (route.path === appPages[i].url)
          {
            return i;
          }
        }
        return 0;
      }
    }
  },
  data() {
    return { 
      description: SettingsService.description
    }
  }
});
